import React from "react"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"

// import TheaterVideo from "@components/Theater/TheaterVideo"
import SizzleVideo from "../../components/reactPlayerTest"
import "../../components/animations/scroll.scss"

function Sizzle({ playing, children, youtube, language }) {
  return (
    <div className="home-sizzle-wrapper">
      <SizzleVideo
        youtube={youtube}
        language={language}
        playing={playing}
        id="test-player-1"
      />

      {children}
    </div>
  )
}

export default Sizzle
